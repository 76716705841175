import {
  IconDogWalking,
  IconHouseVisiting,
  IconPetDayCare,
  IconPetSitting,
  IconDogGrooming,
  IconDogTraining,
  IconPetHosting,
  IconEnvelope,
  IconWhatsapp,
  IconFacebookMessenger,
  IconFacebookColoured,
  IconX,
} from '@madpaws/design-system';

import {
  DOG_GROOMING_SERVICE_ID,
  DOG_TRAINING_SERVICE_ID,
  DOG_WALKING_SERVICE_ID,
  HOUSE_VISITING_SERVICE_ID,
  PET_DAY_CARE_SERVICE_ID,
  PET_HOSTING_SERVICE_ID,
  PET_SITTING_SERVICE_ID,
} from '~/common/constants/app';

import type { ReactElement } from 'react';

// root component's id of Next.js app
export const DOM_CONTAINER_ID = '__next';

export const MADPAWS_PUBLIC_PHONE_NUMBER = '02 8046 6536';

export const ORGANIZATION_DATA = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Madpaws.com.au',
  alternateName: 'Madpaws',
  url: 'https://www.madpaws.com.au',
  logo: 'https://www.madpaws.com.au/static/logo-dark.png',
};

export const WEBSITE_DATA = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: 'Madpaws.com.au',
  alternateName: 'Madpaws',
  url: 'https://www.madpaws.com.au',
};

export type ServiceOption = {
  description?: string;
  icon: ReactElement;
  label: string;
  serviceTypeId: number;
  title: string;
  value: string;
};

export const SERVICE_OPTIONS: ServiceOption[] = [
  {
    serviceTypeId: PET_HOSTING_SERVICE_ID,
    description: `Overnight at the sitter’s home`,
    title: 'Hosting',
    label: 'Pet hosting',
    value: 'petHosting',
    icon: <IconPetHosting size="large" />,
  },
  {
    serviceTypeId: PET_SITTING_SERVICE_ID,
    description: 'Overnight at your home',
    title: 'Sitting',
    label: 'Pet sitting',
    value: 'petSitting',
    icon: <IconPetSitting size="large" />,
  },
  {
    serviceTypeId: PET_DAY_CARE_SERVICE_ID,
    description: 'During working hours',
    title: 'Day care',
    label: 'Pet daycare',
    value: 'petDayCare',
    icon: <IconPetDayCare size="large" />,
  },
  {
    serviceTypeId: HOUSE_VISITING_SERVICE_ID,
    description: 'Drop-in and check on your pet',
    title: 'House visits',
    label: 'House visits',
    value: 'houseVisiting',
    icon: <IconHouseVisiting size="large" />,
  },
  {
    serviceTypeId: DOG_WALKING_SERVICE_ID,
    description: '30 - 45 minutes',
    title: 'Dog walks',
    label: 'Dog walks',
    value: 'dogWalking',
    icon: <IconDogWalking size="large" />,
  },
  {
    serviceTypeId: DOG_GROOMING_SERVICE_ID,
    title: 'Grooming',
    label: 'Dog grooming',
    value: 'grooming',
    icon: <IconDogGrooming size="large" />,
  },
  {
    serviceTypeId: DOG_TRAINING_SERVICE_ID,
    title: 'Training',
    label: 'Dog training',
    value: 'training',
    icon: <IconDogTraining size="large" />,
  },
];

export const SERVICE_OPTIONS_V2: ServiceOption[] = [
  {
    serviceTypeId: PET_HOSTING_SERVICE_ID,
    description: `Your pet will stay over at the sitter’s home`,
    title: 'Hosting',
    label: 'Pet hosting',
    value: 'petHosting',
    icon: <IconPetHosting size="large" />,
  },
  {
    serviceTypeId: PET_SITTING_SERVICE_ID,
    description: 'A sitter will stay overnight at your home',
    title: 'Sitting',
    label: 'Pet sitting',
    value: 'petSitting',
    icon: <IconPetSitting size="large" />,
  },
  {
    serviceTypeId: PET_DAY_CARE_SERVICE_ID,
    description: 'Pet care during working hours',
    title: 'Day care',
    label: 'Pet daycare',
    value: 'petDayCare',
    icon: <IconPetDayCare size="large" />,
  },
  {
    serviceTypeId: HOUSE_VISITING_SERVICE_ID,
    description: 'Schedule a drop-in to check on your pet',
    title: 'House visits',
    label: 'House visits',
    value: 'houseVisiting',
    icon: <IconHouseVisiting size="large" />,
  },
  {
    serviceTypeId: DOG_WALKING_SERVICE_ID,
    description: 'Organise someone to exercise your dog',
    title: 'Dog walks',
    label: 'Dog walks',
    value: 'dogWalking',
    icon: <IconDogWalking size="large" />,
  },
  {
    serviceTypeId: DOG_GROOMING_SERVICE_ID,
    description: 'Have someone groom your dog',
    title: 'Grooming',
    label: 'Dog grooming',
    value: 'grooming',
    icon: <IconDogGrooming size="large" />,
  },
  {
    serviceTypeId: DOG_TRAINING_SERVICE_ID,
    description: 'Get support on training and behaviour',
    title: 'Training',
    label: 'Dog training',
    value: 'training',
    icon: <IconDogTraining size="large" />,
  },
];

type SocialShareLink = {
  href: string;
  icon: ReactElement;
  label: string;
  share_channel: string;
};

export const SOCIAL_SHARE_LINKS: SocialShareLink[] = [
  {
    href: 'mailto:?subject=&body={YOUR_URL}',
    label: 'Email',
    icon: <IconEnvelope />,
    share_channel: 'email',
  },
  {
    href: 'https://api.whatsapp.com/send?text={YOUR_URL}',
    label: 'Whatsapp',
    icon: <IconWhatsapp />,
    share_channel: 'whatsapp',
  },
  {
    href: `https://www.facebook.com/dialog/send?app_id=${process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}&link={YOUR_URL}&redirect_uri=${WEBSITE_DATA.url}`,
    label: 'Messenger',
    icon: <IconFacebookMessenger />,
    share_channel: 'messenger',
  },
  {
    href: `https://www.facebook.com/dialog/share?app_id=${process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}&href={YOUR_URL}`,
    label: 'Facebook',
    icon: <IconFacebookColoured />,
    share_channel: 'facebook',
  },
  {
    href: 'https://twitter.com/intent/tweet?url={YOUR_URL}&via=https://twitter.com/madpawsau',
    label: 'X (Twitter)',
    icon: <IconX />,
    share_channel: 'twitter',
  },
];
