import { UNPROCESSABLE_CONTENT_CODE } from '~/common/constants/errorCodes';

import { RestApi } from '../restApi';
import { getErrorMessage } from '../utils';

import type {
  GetPasswordResetEmailResponse,
  LoginResponse,
  SocialLoginArgs,
  ValidateEmailResponse,
} from './types';
import type { AxiosError, AxiosResponse } from 'axios';

export class AuthApi extends RestApi {
  static async getPasswordResetEmail(email: string): Promise<GetPasswordResetEmailResponse | null> {
    try {
      const response: AxiosResponse<GetPasswordResetEmailResponse> = await this.woofApi.post(
        'v1/password/email',
        {
          email,
          client_id: Number(process.env.NEXT_PUBLIC_CLIENT_ID ?? 0),
          client_secret: process.env.NEXT_PUBLIC_CLIENT_SECRET ?? '',
        }
      );

      return { ...response.data };
    } catch (error) {
      this.handleError(error as AxiosError);
      throw new Error(getErrorMessage(error as AxiosError));
    }
  }

  static async loginWithSocials(
    args: SocialLoginArgs,
    signUpMethod: 'google' | 'facebook'
  ): Promise<LoginResponse | null> {
    try {
      const response: AxiosResponse<LoginResponse> = await this.woofApi.post(
        `/v3/users/login_with_${signUpMethod}`,
        { ...args }
      );

      return { ...response.data };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (
        error.response.status === UNPROCESSABLE_CONTENT_CODE &&
        error.response?.data?.userNeedsToAcceptTerms
      ) {
        return error.response.data;
      }

      return this.handleError(error as AxiosError);
    }
  }

  static async resetPassword(
    email: string,
    password: string,
    passwordConfirmation: string,
    token: string
  ): Promise<GetPasswordResetEmailResponse | null> {
    try {
      const response: AxiosResponse<GetPasswordResetEmailResponse> = await this.woofApi.post(
        'v1/password',
        {
          email,
          password,
          passwordConfirmation,
          token,
          grant_type: 'password',
          client_id: Number(process.env.NEXT_PUBLIC_CLIENT_ID ?? 0),
          client_secret: process.env.NEXT_PUBLIC_CLIENT_SECRET ?? '',
        }
      );

      return { ...response.data };
    } catch (error) {
      this.handleError(error as AxiosError);
      throw new Error(getErrorMessage(error as AxiosError));
    }
  }

  static async validateEmail(email: string): Promise<ValidateEmailResponse | null> {
    try {
      const response: AxiosResponse<ValidateEmailResponse> = await this.woofApi.post(
        '/v3/users/validate_email',
        { email }
      );

      return { ...response.data };
    } catch (error) {
      return this.handleError(error as AxiosError);
    }
  }
}
