import * as EppoSdk from '@eppo/js-client-sdk';
import { useContext, useMemo } from 'react';

import { EppoContext } from './EppoProvider';

type Context = {
  isError: boolean;
  isInitialized: boolean;
  subjectAttributes: Record<string, string>;
  subjectKey: string;
};

const eppoClient = EppoSdk.getInstance();
const defaultValue = 'off';
const IS_LHCI_TESTING = process.env.LHCI_TESTING;

export const useEppo = (): Context => useContext(EppoContext);

export const useEppoStringAssignment = (featureKey: string): string => {
  const { isInitialized, subjectAttributes, subjectKey } = useEppo();

  /**
   * Get the assignment for the given feature key
   * If the eppo client is not initialized, return the default value
   */
  const assignment = useMemo(() => {
    /**
     * Return default if not initialised or the LHCI_TESTING flag is set
     * This is to ensure that the tests run without any performance issues
     */
    if (!isInitialized || IS_LHCI_TESTING) {
      return defaultValue;
    }

    return eppoClient.getStringAssignment(featureKey, subjectKey, subjectAttributes, defaultValue);
  }, [featureKey, subjectKey, subjectAttributes, isInitialized]);

  return assignment;
};
