import { useMutation } from '@apollo/client';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { ACCESS_TOKEN_COOKIE_NAME, MD_AUTH_LOCAL_STORAGE_KEY } from '~/common/constants/auth';
import { removeLocalStorage, setLocalStorage } from '~/utils/localStorage';
import { sentryException } from '~/utils/sentryHelpers';

import { GoogleSignUpDialog } from './SignUpDialog/GoogleSignUpDialog';
import { WHITE_LIST_OF_ROUTES } from './constants';
import { SIGN_IN_WITH_GOOGLE } from './queries';
import { SignUpMethod } from '../AuthenticationProvider/AuthDialog/AuthDialog';
import { trackSignUpFEEvent } from '../AuthenticationProvider/AuthDialog/utils';
import { getSocialLoginArgs } from '../AuthenticationProvider/helpers/getSocialLoginArgs';
import { useUserLoggedInContext } from '../UserAuthProvider/UserAuthProvider';

import type { ReactElement, ReactNode } from 'react';
import type { GoogleLoginArgs } from '~/api/graphql/googleSignIn/GoogleSignInApi/types';
import type { LoginResponse } from '~/api/graphql/googleSignIn/typeDefs';

type Props = {
  children: ReactNode;
  route: string;
};

export type GoogleSignUpProps = {
  firstName?: string;
  lastName?: string;
  newUserAcceptedTerms?: boolean;
  receiveSpecialOffers?: boolean;
  socialToken: string;
};

export const GoogleOneTapLoginProvider = ({ route, children }: Props): ReactElement => {
  const router = useRouter();
  const { isUserLoggedIn, setIsUserLoggedIn } = useUserLoggedInContext();
  const [isSignUpDialogVisible, setIsSignUpDialogVisible] = useState<boolean>(false);
  const [loginResponse, setLoginResponse] = useState<LoginResponse | null>(null);
  const [isTermsFormSubmitting, setIsTermsFormSubmitting] = useState<boolean>(false);
  const [termsFormError, setTermsFormError] = useState<string | null>(null);

  // display one tap for certain list of pages, like HP or SFS:
  const isEnabled = WHITE_LIST_OF_ROUTES.includes(route) && !isUserLoggedIn;

  const postLoginAction = (): void => {
    setIsSignUpDialogVisible(false);
    setIsUserLoggedIn(true);
  };

  const onGoogleSignInFailed = (error: Error): void => {
    setTermsFormError(error.message);
    setIsTermsFormSubmitting(false);
    sentryException(error, 'signInWithGoogle onError');
  };

  const onGoogleSignInCompleted = (data: { signInWithGoogle: LoginResponse }): void => {
    const { signInWithGoogle: auth } = data;
    const { userNeedsToAcceptTerms, isUserPhoneNumberVerified } = auth;
    // show the google sign up dialog if user needs to accept TnCs
    if (userNeedsToAcceptTerms) {
      setTermsFormError(null);
      setIsSignUpDialogVisible(true);
      setLoginResponse(auth);

      return;
    }

    // or show the google sign up dialog if user has unverified phone number
    if (isUserPhoneNumberVerified === false) {
      setIsSignUpDialogVisible(true);
      setLoginResponse(auth);
      Cookies.set(ACCESS_TOKEN_COOKIE_NAME, JSON.stringify({ tokens: auth }) ?? '');
      setLocalStorage(MD_AUTH_LOCAL_STORAGE_KEY, { ...auth });

      return;
    }

    // otherwise complete the login and no need to show the dialog
    Cookies.set(ACCESS_TOKEN_COOKIE_NAME, JSON.stringify({ tokens: auth }) ?? '');
    setLocalStorage(MD_AUTH_LOCAL_STORAGE_KEY, { ...auth });
    setIsTermsFormSubmitting(false);
    setTermsFormError(null);
    postLoginAction();
  };

  const [signInWithGoogle] = useMutation<{ signInWithGoogle: LoginResponse }>(SIGN_IN_WITH_GOOGLE, {
    onCompleted: onGoogleSignInCompleted,
    onError: onGoogleSignInFailed,
  });

  const submitGoogleSignIn = async (
    socialToken?: string | null,
    signUpProps?: GoogleSignUpProps,
    onSuccess?: (data: LoginResponse, queryParam?: string, loginMethod?: SignUpMethod) => void
  ): Promise<void> => {
    if (!socialToken) {
      sentryException('Google credential is invalid', `socialToken: ${socialToken}`);
      return;
    }

    const {
      route: routerRoute,
      query: { refSource, referralCode },
    } = router;

    const extraArgs = {
      one_tap: routerRoute,
      refSource: (refSource as string) ?? null,
      referralCode: (referralCode as string) ?? null,
    };

    const googleLoginArgs: GoogleLoginArgs = (await getSocialLoginArgs(
      socialToken,
      SignUpMethod.GOOGLE,
      signUpProps,
      extraArgs
    )) as GoogleLoginArgs;

    try {
      const response = await signInWithGoogle({
        variables: { googleLoginArgs: { ...googleLoginArgs } },
      });
      onSuccess?.(response.data?.signInWithGoogle as LoginResponse, undefined, SignUpMethod.GOOGLE);
    } catch (error) {
      sentryException(
        error,
        `signInWithGoogle mutation failed, googleLoginArgs: ${JSON.stringify(
          googleLoginArgs,
          null,
          2
        )}`
      );
    }
  };

  const onGoogleSignUpFormSubmit = (data: GoogleSignUpProps): void => {
    setIsTermsFormSubmitting(true);
    setTermsFormError(null);
    submitGoogleSignIn(data.socialToken, data, trackSignUpFEEvent);
  };

  const onGoogleSignUpDialogClose = (): void => {
    removeLocalStorage(MD_AUTH_LOCAL_STORAGE_KEY);
    Cookies.remove(ACCESS_TOKEN_COOKIE_NAME);
    setIsUserLoggedIn(false);
    setIsSignUpDialogVisible(false);
    setTermsFormError(null);
  };

  const onPageUnload = (): void => {
    if (!isUserLoggedIn) {
      onGoogleSignUpDialogClose();
    }
  };

  // handle the dialog close on page refresh
  useEffect(() => {
    window.addEventListener('beforeunload', onPageUnload);
    return () => {
      window.removeEventListener('beforeunload', onPageUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggedIn]);

  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      submitGoogleSignIn(credentialResponse.credential);
    },
    onError: () => {
      sentryException('useGoogleOneTapLogin method failed');
    },
    disabled: !isEnabled,
    cancel_on_tap_outside: false,
    use_fedcm_for_prompt: true,
  });

  return (
    <>
      {children}
      <GoogleSignUpDialog
        data={loginResponse}
        isOpen={isSignUpDialogVisible}
        isTermsFormSubmitting={isTermsFormSubmitting}
        onDialogClose={onGoogleSignUpDialogClose}
        onSubmit={onGoogleSignUpFormSubmit}
        onSuccessLogin={postLoginAction}
        termsFormError={termsFormError}
      />
    </>
  );
};
