import { Button } from '@madpaws/design-system';
import { useGoogleLogin } from '@react-oauth/google';
import getConfig from 'next/config';
import React from 'react';

import type { CodeResponse } from '@react-oauth/google';
import type { ReactElement } from 'react';

const { publicRuntimeConfig } = getConfig();

type Props = {
  onClickLogin: () => void;
  onLoginSuccess: (token: string) => void;
};

export const GoogleLoginButton = ({ onLoginSuccess, onClickLogin }: Props): ReactElement => {
  const handleLoginSuccess = (response: CodeResponse): void => {
    onLoginSuccess(response.code);
  };

  const loginWithGoogle = useGoogleLogin({
    flow: 'auth-code',
    scope: 'email',
    onSuccess: handleLoginSuccess,
    onError: (error) => console.log(error.error_description),
  });

  const handleGoogleLogin = (): void => {
    loginWithGoogle();
    onClickLogin();
  };

  return (
    <Button
      iconLeading={
        <img
          alt="google icon"
          src={`${publicRuntimeConfig.staticPath}/icons/sign-in/iconGoogle.svg`}
        />
      }
      isFullBleed
      label="Continue with Google"
      onClick={handleGoogleLogin}
      variant="secondary"
    />
  );
};
