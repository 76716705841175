import Cookies from 'js-cookie';

import { QANTAS_TOKEN_COOKIE_NAME } from '~/common/constants/auth';
import {
  FACEBOOK_LOGIN_GRANT_TYPE,
  GOOGLE_LOGIN_GRANT_TYPE,
} from '~/components/GoogleOAuthProvider/constants';

import { SignUpMethod } from '../AuthDialog/AuthDialog';

import type { SocialLoginArgs } from '~/services/auth/types';

export type SocialSignUpProps = {
  firstName?: string;
  lastName?: string;
  newUserAcceptedTerms?: boolean;
  receiveSpecialOffers?: boolean;
};

export const getSocialLoginArgs = async (
  token: string,
  socialType: SignUpMethod | null,
  signUpProps?: SocialSignUpProps,
  extraArgs?: Record<string, unknown>
): Promise<SocialLoginArgs> => {
  const qantasToken = Cookies.get(QANTAS_TOKEN_COOKIE_NAME) ?? '';

  const tokenArgs =
    socialType === SignUpMethod.FACEBOOK ? { fb_token: token } : { google_token: token };

  let socialLoginArgs: SocialLoginArgs = {
    ...tokenArgs,
    client_id: Number(process.env.NEXT_PUBLIC_CLIENT_ID ?? 0),
    client_secret: process.env.NEXT_PUBLIC_CLIENT_SECRET ?? '',
    grant_type:
      socialType === SignUpMethod.FACEBOOK ? FACEBOOK_LOGIN_GRANT_TYPE : GOOGLE_LOGIN_GRANT_TYPE,
    newUserAcceptedTerms: false,
    qantasSrc: !!qantasToken,
    qantasToken,
    receiveSpecialOffers: false,
    ...extraArgs,
  };

  // await to check if 2FA is enabled
  // TODO (WEB-2333): Move to Eppo
  // await getTreatments();
  // const isTwoFactorAuthEnabled =
  //   treatments[TWO_FACTOR_AUTHENTICATION_SOCIAL_LOGINS_ID] ===
  //   TWO_FACTOR_AUTHENTICATION_SOCIAL_LOGINS_ID_VALUES.ON;
  const isTwoFactorAuthEnabled = false;

  if (isTwoFactorAuthEnabled) {
    socialLoginArgs = {
      ...socialLoginArgs,
      hasLimitedScope: true,
    };
  }

  if (signUpProps) {
    const { firstName, lastName, newUserAcceptedTerms, receiveSpecialOffers } = signUpProps;

    socialLoginArgs = {
      ...socialLoginArgs,
      first_name: firstName,
      last_name: lastName,
      newUserAcceptedTerms: newUserAcceptedTerms ?? false,
      receiveSpecialOffers: receiveSpecialOffers ?? false,
    };
  }

  console.log(socialLoginArgs);

  return socialLoginArgs;
};
