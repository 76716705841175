import type { AxiosError } from 'axios';

export const getErrorMessage = (error: AxiosError): string => {
  if (error.response) {
    const axiosError = error as AxiosError<{ errors: [{ detail: string }] }>;
    const errorMessage =
      axiosError.response?.data?.errors?.[0]?.detail ||
      'Something went wrong. Please try again later.';
    return errorMessage;
  } else {
    // Handle non-Axios errors
    return 'An unexpected error occurred. Please try again later.';
  }
};
