import { createContext, useContext, useEffect, useState } from 'react';

import { UserApi } from '~/services/user/UserApi';

import { identify } from '../analytics/analytics';

import type { ReactElement } from 'react';
import type { UserDetails } from '~/common/types/user';

export type Context = {
  getUserDetails: () => Promise<void>;
  isUserDetailsLoading: boolean;
  isUserSitter: boolean;
  numberOfUnreadMessages: number | null;
  user: UserDetails | null;
};

// export for testing purpose
export const UserDetailsContext = createContext<Context>({
  isUserDetailsLoading: true,
  isUserSitter: false,
  numberOfUnreadMessages: null,
  user: null,
  getUserDetails: async () => {},
});

export const useUserDetailsContext = (): Context => useContext(UserDetailsContext);

type Props = {
  children: ReactElement | ReactElement[];
  userInfo: {
    numberOfUnreadMessages: number | null;
    userDetails: UserDetails | null;
  };
};

export const UserDetailsProvider = ({
  children,
  userInfo: { userDetails, numberOfUnreadMessages },
}: Props): ReactElement => {
  const [user, setUser] = useState<UserDetails | null>(userDetails);

  useEffect(() => {
    if (user) {
      identify(user.id, { isSitter: user.isSitter });
    }
  }, [user]);

  const getUserDetails = async (): Promise<void> => {
    const userRefresh = await UserApi.getUserDetails();
    setUser(userRefresh);
  };

  return (
    <UserDetailsContext.Provider
      value={{
        isUserDetailsLoading: false,
        isUserSitter: user?.isSitter ?? false,
        user,
        numberOfUnreadMessages,
        getUserDetails,
      }}
    >
      {children}
    </UserDetailsContext.Provider>
  );
};
