import { UNAUTHENTICATED_CODE } from '~/common/constants/errorCodes';

import { RestApi } from '../restApi';

import type {
  GetUserBalanceResponse,
  GetUserNotificationSettingsResponse,
  GetUserResponse,
  UpdateUserNotificationPrefsResponse,
  ValidateUserBalanceResponse,
} from './types';
import type { AxiosError, AxiosResponse } from 'axios';
import type { UserBalances, UserDetails } from '~/common/types/user';

export class UserApi extends RestApi {
  static async getUserBalance(userId: number): Promise<UserBalances | null> {
    try {
      const response: AxiosResponse<GetUserBalanceResponse> = await this.woofApi.get(
        `v1/users/${userId}/balance`
      );

      return { ...response.data.data };
    } catch (error) {
      return this.handleError(error as AxiosError);
    }
  }

  static async getUserDetails(): Promise<UserDetails | null> {
    try {
      const { data } = await this.woofApi.get<GetUserResponse>('/v1/user');

      const { providesRwb, providesRwbPerService, roles, ...userDetails } = data.data;
      const isSitter = roles.data.some((role) => role.slug === 'sitter');

      return { isSitter, ...userDetails };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.extensions.response.status === UNAUTHENTICATED_CODE) {
        return null;
      }

      throw error;
    }
  }

  static async getUserNotificationSettings(): Promise<GetUserNotificationSettingsResponse['data']> {
    try {
      const response: AxiosResponse<GetUserNotificationSettingsResponse> = await this.woofApi.get(
        'v1/users/notificationSettings'
      );

      return { ...response.data.data };
    } catch (error) {
      this.handleError(error as AxiosError);
      throw error;
    }
  }

  static async updateUserNotificationSettings({
    bookingStatusUpdates,
    newMessages,
  }: {
    bookingStatusUpdates: boolean;
    newMessages: boolean;
  }): Promise<UpdateUserNotificationPrefsResponse> {
    try {
      const response: AxiosResponse<UpdateUserNotificationPrefsResponse> = await this.woofApi.patch(
        'v1/users/notificationSettings',
        { bookingStatusUpdates, newMessages }
      );

      return { ...response.data };
    } catch (error) {
      this.handleError(error as AxiosError);
      throw error;
    }
  }

  static async validateCreditsCode(code: string): Promise<ValidateUserBalanceResponse | null> {
    try {
      const response: AxiosResponse<ValidateUserBalanceResponse> = await this.woofApi.post(
        'v1/giftcards',
        { code }
      );

      return { ...response.data };
    } catch (error) {
      this.handleError(error as AxiosError);
      throw error;
    }
  }
}
