import {
  IconDogGrooming,
  IconDogTraining,
  IconDogWalking,
  IconHouseVisiting,
  IconPetDayCare,
  IconPetSitting,
} from '@madpaws/design-system';

import type { ServiceTypeTimeUnit } from '@madpaws/design-system';
import type { Props as IconProps } from '@madpaws/design-system/dist/components/icons/types';
import type { ReactElement } from 'react';
import type { Chronology, PetTypes, WeekDays } from '~/common/types/search';

export const LEGAL_LINKS = [
  {
    label: 'Privacy Policy',
    href: 'https://www.madpaws.com.au/privacy-policy',
  },
  {
    label: 'Terms of Service',
    href: 'https://www.madpaws.com.au/terms-of-service',
  },
];

export const PET_HOSTING_SERVICE_ID = 1;
export const PET_SITTING_SERVICE_ID = 2;
export const PET_DAY_CARE_SERVICE_ID = 3;
export const HOUSE_VISITING_SERVICE_ID = 4;
export const DOG_WALKING_SERVICE_ID = 5;
export const DOG_GROOMING_SERVICE_ID = 6;
export const DOG_TRAINING_SERVICE_ID = 7;

export const SERVICE_TYPES_IDS = [
  PET_HOSTING_SERVICE_ID,
  PET_SITTING_SERVICE_ID,
  PET_DAY_CARE_SERVICE_ID,
  HOUSE_VISITING_SERVICE_ID,
  DOG_WALKING_SERVICE_ID,
  DOG_GROOMING_SERVICE_ID,
  DOG_TRAINING_SERVICE_ID,
];

export const DEFAULT_SERVICE_TYPE_ID = PET_HOSTING_SERVICE_ID;

export const DEFAULT_WEEK_DAYS_DATA: WeekDays = {
  sunday: false,
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
};

export const DEFAULT_PET_TYPES_DATA: PetTypes = {
  bird: '',
  cat: '',
  creature: '',
  giantDog: '',
  largeDog: '',
  mediumDog: '',
  puppy: '',
  smallDog: '',
};

export const DEFAULT_CHRONOLOGY_DETAILS: Chronology = {
  startDate: '',
  endDate: '',
  rwbStartDate: '',
  scheduledDates: [],
  weekDays: DEFAULT_WEEK_DAYS_DATA,
};

export const SERVICE_TYPES_SUPPORTING_RWB = [
  PET_DAY_CARE_SERVICE_ID,
  HOUSE_VISITING_SERVICE_ID,
  DOG_WALKING_SERVICE_ID,
];

export const OVERNIGHT_SERVICE_TYPE_IDS = [PET_HOSTING_SERVICE_ID, PET_SITTING_SERVICE_ID];

export const DAYTIME_SERVICE_TYPE_IDS = [
  PET_DAY_CARE_SERVICE_ID,
  HOUSE_VISITING_SERVICE_ID,
  DOG_WALKING_SERVICE_ID,
  DOG_GROOMING_SERVICE_ID,
  DOG_TRAINING_SERVICE_ID,
];

export type ServiceTypeDetails = {
  icon: ({ size }: IconProps) => ReactElement;
  id: number;
  name: string;
  slug: string;
  timeUnit: ServiceTypeTimeUnit;
};

export const SERVICE_TYPES_NAMES = {
  PET_HOSTING: 'Pet Hosting',
  PET_SITTING: 'Pet Sitting',
  PET_DAY_CARE: 'Pet Day Care',
  HOUSE_VISITING: 'House Visiting',
  DOG_WALKING: 'Dog Walking',
  DOG_GROOMING: 'Dog Grooming',
  DOG_TRAINING: 'Dog Training',
};

export const SERVICE_TYPES: ServiceTypeDetails[] = [
  {
    icon: IconPetSitting,
    id: PET_HOSTING_SERVICE_ID,
    slug: 'petHosting',
    name: SERVICE_TYPES_NAMES.PET_HOSTING,
    timeUnit: 'night',
  },
  {
    icon: IconPetSitting,
    id: PET_SITTING_SERVICE_ID,
    slug: 'petSitting',
    name: SERVICE_TYPES_NAMES.PET_SITTING,
    timeUnit: 'night',
  },
  {
    icon: IconPetDayCare,
    id: PET_DAY_CARE_SERVICE_ID,
    slug: 'petDayCare',
    name: SERVICE_TYPES_NAMES.PET_DAY_CARE,
    timeUnit: 'day',
  },
  {
    icon: IconHouseVisiting,
    id: HOUSE_VISITING_SERVICE_ID,
    slug: 'houseVisiting',
    name: SERVICE_TYPES_NAMES.HOUSE_VISITING,
    timeUnit: 'visit',
  },
  {
    icon: IconDogWalking,
    id: DOG_WALKING_SERVICE_ID,
    slug: 'dogWalking',
    name: SERVICE_TYPES_NAMES.DOG_WALKING,
    timeUnit: 'walk',
  },
  {
    icon: IconDogGrooming,
    id: DOG_GROOMING_SERVICE_ID,
    slug: 'grooming',
    name: SERVICE_TYPES_NAMES.DOG_GROOMING,
    timeUnit: 'session',
  },
  {
    icon: IconDogTraining,
    id: DOG_TRAINING_SERVICE_ID,
    slug: 'training',
    name: SERVICE_TYPES_NAMES.DOG_TRAINING,
    timeUnit: 'session',
  },
];

export const SERVICE_TYPES_TIME_UNITS: {
  [key: number]: string;
} = SERVICE_TYPES.reduce((obj, service) => ({ ...obj, [service.id]: service.timeUnit }), {});

export const SERVICE_TYPE_SLUGS_TO_SERVICE_TYPE_IDS: {
  [key: string]: number;
} = SERVICE_TYPES.reduce((obj, service) => ({ ...obj, [service.slug]: service.id }), {});

export const SERVICE_TYPE_IDS_TO_SERVICE_TYPE_SLUGS: {
  [key: number]: string;
} = SERVICE_TYPES.reduce((obj, service) => ({ ...obj, [service.id]: service.slug }), {});

export const DOG_SMALL_PET_TYPE_ID = 1;
export const DOG_MEDIUM_PET_TYPE_ID = 2;
export const DOG_LARGE_PET_TYPE_ID = 3;
export const DOG_XLARGE_PET_TYPE_ID = 4;
export const DOG_PUPPY_PET_TYPE_ID = 5;
export const CAT_PET_TYPE_ID = 6;
export const BIRD_PET_TYPE_ID = 7;
export const CRITTER_PET_TYPE_ID = 8;

export const PET_TYPE_IDS = [
  DOG_SMALL_PET_TYPE_ID,
  DOG_MEDIUM_PET_TYPE_ID,
  DOG_LARGE_PET_TYPE_ID,
  DOG_XLARGE_PET_TYPE_ID,
  DOG_PUPPY_PET_TYPE_ID,
  CAT_PET_TYPE_ID,
  BIRD_PET_TYPE_ID,
  CRITTER_PET_TYPE_ID,
];

export const PET_TYPE_LABELS = {
  PUPPY: 'Puppy',
  SMALL_DOG: 'Small dog',
  MEDIUM_DOG: 'Medium dog',
  LARGE_DOG: 'Large dog',
  GIANT_DOG: 'Giant dog',
  CAT: 'Cat',
  RABBIT: 'Rabbit/Guinea pig',
  BIRD: 'Bird',
};

export const PET_TYPE_NAMES = {
  PUPPY: 'puppy',
  SMALL_DOG: 'smallDog',
  MEDIUM_DOG: 'mediumDog',
  LARGE_DOG: 'largeDog',
  GIANT_DOG: 'giantDog',
  CAT: 'cat',
  RABBIT: 'creature',
  BIRD: 'bird',
};

// No description or weight range for birds, rabbits and other cats
export const PET_TYPE_INFO = {
  PUPPY: 'Under 6 months',
  SMALL_DOG: '0-10 kg',
  MEDIUM_DOG: '11-20 kg',
  LARGE_DOG: '21-40 kg',
  GIANT_DOG: '41+ kg',
};

export const PET_TYPES = [
  {
    id: DOG_PUPPY_PET_TYPE_ID,
    label: PET_TYPE_LABELS.PUPPY,
    name: PET_TYPE_NAMES.PUPPY,
    labelPlural: 'Puppies',
    description: PET_TYPE_INFO.PUPPY,
  },
  {
    id: DOG_SMALL_PET_TYPE_ID,
    label: PET_TYPE_LABELS.SMALL_DOG,
    weightRange: PET_TYPE_INFO.SMALL_DOG,
    name: PET_TYPE_NAMES.SMALL_DOG,
    labelPlural: 'Small dogs',
  },
  {
    id: DOG_MEDIUM_PET_TYPE_ID,
    label: PET_TYPE_LABELS.MEDIUM_DOG,
    weightRange: PET_TYPE_INFO.MEDIUM_DOG,
    name: PET_TYPE_NAMES.MEDIUM_DOG,
    labelPlural: 'Medium dogs',
  },
  {
    id: DOG_LARGE_PET_TYPE_ID,
    label: PET_TYPE_LABELS.LARGE_DOG,
    weightRange: PET_TYPE_INFO.LARGE_DOG,
    name: PET_TYPE_NAMES.LARGE_DOG,
    labelPlural: 'Large dogs',
  },
  {
    id: DOG_XLARGE_PET_TYPE_ID,
    label: PET_TYPE_LABELS.GIANT_DOG,
    weightRange: PET_TYPE_INFO.GIANT_DOG,
    name: PET_TYPE_NAMES.GIANT_DOG,
    labelPlural: 'Giant dogs',
  },
  {
    id: CAT_PET_TYPE_ID,
    label: PET_TYPE_LABELS.CAT,
    name: PET_TYPE_NAMES.CAT,
    labelPlural: 'Cats',
  },
  {
    id: CRITTER_PET_TYPE_ID,
    label: PET_TYPE_LABELS.RABBIT,
    name: PET_TYPE_NAMES.RABBIT,
    labelPlural: 'Rabbits/Guinea pigs',
  },
  {
    id: BIRD_PET_TYPE_ID,
    label: PET_TYPE_LABELS.BIRD,
    name: PET_TYPE_NAMES.BIRD,
    labelPlural: 'Birds',
  },
];

export const PET_TYPE_NAMES_TO_PET_TYPE_IDS: {
  [key: string]: number;
} = PET_TYPES.reduce((obj, pet) => ({ ...obj, [pet.name]: pet.id }), {});

export const PET_TYPE_IDS_TO_PET_TYPE_NAMES: {
  [key: number]: string;
} = PET_TYPES.reduce((obj, pet) => ({ ...obj, [pet.id]: pet.name }), {});

export const FORM_IDS = {
  SOCIAL_SIGN_UP_FORM: 'SOCIAL_SIGN_UP_FORM',
  SIGN_UP_FORM: 'SIGN_UP_FORM',
};

export const SITTER_PROFILE_PREFIX = 'petsitter';
