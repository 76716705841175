/* eslint-disable quotes */
import {
  DOG_GROOMING_SERVICE_ID,
  DOG_TRAINING_SERVICE_ID,
  DOG_WALKING_SERVICE_ID,
  HOUSE_VISITING_SERVICE_ID,
  PET_DAY_CARE_SERVICE_ID,
  PET_HOSTING_SERVICE_ID,
  PET_SITTING_SERVICE_ID,
} from './app';

import type { BadgeIcons } from '@madpaws/design-system';

export const DEFAULT_PAGE_SIZE = 20;

export const searchServiceSlugMapping: { [key: string]: number } = {
  petsitters: PET_HOSTING_SERVICE_ID,
  petminding: PET_SITTING_SERVICE_ID,
  doggydaycare: PET_DAY_CARE_SERVICE_ID,
  housevisits: HOUSE_VISITING_SERVICE_ID,
  dogwalkers: DOG_WALKING_SERVICE_ID,
  doggroomers: DOG_GROOMING_SERVICE_ID,
  dogtrainers: DOG_TRAINING_SERVICE_ID,
};

export const DEFAULT_SERVICE_SLUG = 'petsitters';
export const DEFAULT_SEARCH_PAGE_SLUG = `/${DEFAULT_SERVICE_SLUG}/sydney-nsw`;

export const EMPTY_SERVICE_TYPE_ID = 0;
export const EMPTY_SERVICE = { type: '', quantity: 0 };

export const SERVICE_TYPE_BY_NAME_MAPPING: {
  [key: string]: {
    id: number;
    seoTitle: string;
    serviceJob: string;
    serviceName: string;
    serviceTitle: string;
  };
} = {
  petsitters: {
    id: PET_HOSTING_SERVICE_ID,
    serviceName: 'Pet Sitting',
    serviceTitle: 'Pet Sitting & Home Dog Boarding',
    serviceJob: 'Pet Sitters',
    seoTitle: 'Pet Sitters',
  },
  petminding: {
    id: PET_SITTING_SERVICE_ID,
    serviceName: 'House Sitting & Pet Minding',
    serviceTitle: 'House Sitting & Pet Minding',
    serviceJob: 'Pet Sitters',
    seoTitle: 'Pet Sitters',
  },
  doggydaycare: {
    id: PET_DAY_CARE_SERVICE_ID,
    serviceName: 'Pet Day Care',
    serviceTitle: 'Doggy Daycare',
    serviceJob: 'Dog Sitters',
    seoTitle: 'Dog Day Care',
  },
  housevisits: {
    id: HOUSE_VISITING_SERVICE_ID,
    serviceName: 'House Visiting',
    serviceTitle: 'House Visits',
    serviceJob: 'Pet Minders',
    seoTitle: 'Pet Minders',
  },
  dogwalkers: {
    id: DOG_WALKING_SERVICE_ID,
    serviceName: 'Dog Walking',
    serviceTitle: 'Dog Walking',
    serviceJob: 'Dog Walkers',
    seoTitle: 'Dog Walkers',
  },
  doggroomers: {
    id: DOG_GROOMING_SERVICE_ID,
    serviceName: 'Dog Grooming',
    serviceTitle: 'Dog Grooming',
    serviceJob: 'Dog Groomers',
    seoTitle: 'Dog Groomers',
  },
  dogtrainers: {
    id: DOG_TRAINING_SERVICE_ID,
    serviceName: 'Dog Training',
    serviceTitle: 'Dog Training',
    serviceJob: 'Dog Trainers',
    seoTitle: 'Dog Trainers',
  },
  'pet-groomers': {
    id: DOG_GROOMING_SERVICE_ID,
    serviceName: 'Pet Grooming',
    serviceTitle: 'Pet Grooming',
    serviceJob: 'Pet Groomers',
    seoTitle: 'Pet Groomers',
  },
  'pet-boarding': {
    id: PET_HOSTING_SERVICE_ID,
    serviceName: 'Pet Boarding',
    serviceTitle: 'Pet Boarding',
    serviceJob: 'Pet Boarders',
    seoTitle: 'Pet Boarders',
  },
  'dog-boarding': {
    id: PET_HOSTING_SERVICE_ID,
    serviceName: 'Dog Boarding',
    serviceTitle: 'Dog Boarding',
    serviceJob: 'Dog Boarders',
    seoTitle: 'Dog Boarders',
  },
  'dog-washing': {
    id: DOG_GROOMING_SERVICE_ID,
    serviceName: 'Dog Washing',
    serviceTitle: 'Dog Washing',
    serviceJob: 'Dog Washers',
    seoTitle: 'Dog Washers',
  },
  'dog-sitting': {
    id: PET_HOSTING_SERVICE_ID,
    serviceName: 'Dog Sitting',
    serviceTitle: 'Dog Sitting',
    serviceJob: 'Dog Sitters',
    seoTitle: 'Dog Sitters',
  },
  'dog-minding': {
    id: PET_HOSTING_SERVICE_ID,
    serviceName: 'Dog Minding',
    serviceTitle: 'Dog Minding',
    serviceJob: 'Dog Minders',
    seoTitle: 'Dog Minders',
  },
  'cat-boarding': {
    id: PET_SITTING_SERVICE_ID,
    serviceName: 'Cat Boarding',
    serviceTitle: 'Cat Boarding',
    serviceJob: 'Cat Boarders',
    seoTitle: 'Cat Boarders',
  },
  'cat-sitting': {
    id: HOUSE_VISITING_SERVICE_ID,
    serviceName: 'Cat Sitting',
    serviceTitle: 'Cat Sitting',
    serviceJob: 'Cat Sitters',
    seoTitle: 'Cat Sitters',
  },
  'rabbit-boarding': {
    id: HOUSE_VISITING_SERVICE_ID,
    serviceName: 'Rabbit Boarding',
    serviceTitle: 'Rabbit Boarding',
    serviceJob: 'Rabbit Boarders',
    seoTitle: 'Rabbit Boarders',
  },
  'rabbit-sitting': {
    id: HOUSE_VISITING_SERVICE_ID,
    serviceName: 'Rabbit Sitting',
    serviceTitle: 'Rabbit Sitting',
    serviceJob: 'Rabbit Sitters',
    seoTitle: 'Rabbit Sitters',
  },
  'puppy-sitting': {
    id: HOUSE_VISITING_SERVICE_ID,
    serviceName: 'Puppy Sitting',
    serviceTitle: 'Puppy Sitting',
    serviceJob: 'Puppy Sitters',
    seoTitle: 'Puppy Sitters',
  },
  'bird-sitting': {
    id: HOUSE_VISITING_SERVICE_ID,
    serviceName: 'Bird Sitting',
    serviceTitle: 'Bird Sitting',
    serviceJob: 'Bird Sitters',
    seoTitle: 'Bird Sitters',
  },
  'bird-boarding': {
    id: HOUSE_VISITING_SERVICE_ID,
    serviceName: 'Bird Boarding',
    serviceTitle: 'Bird Boarding',
    serviceJob: 'Bird Boarders',
    seoTitle: 'Bird Boarders',
  },
  'puppy-daycare': {
    id: PET_DAY_CARE_SERVICE_ID,
    serviceName: 'Puppy Daycare',
    serviceTitle: 'Puppy Daycare',
    serviceJob: 'Puppy Sitters',
    seoTitle: 'Puppy Sitters',
  },
  'dog-feeding': {
    id: PET_DAY_CARE_SERVICE_ID,
    serviceName: 'Dog Feeding',
    serviceTitle: 'Dog Feeding',
    serviceJob: 'Dog Feeders',
    seoTitle: 'Dog Feeders',
  },
  'cat-feeding': {
    id: PET_DAY_CARE_SERVICE_ID,
    serviceName: 'Cat Feeding',
    serviceTitle: 'Cat Feeding',
    serviceJob: 'Cat Feeders',
    seoTitle: 'Cat Feeders',
  },
};

export const DEFAULT_SERVICE_NAME = SERVICE_TYPE_BY_NAME_MAPPING.petsitters.serviceName;
export const DEFAULT_SERVICE_JOB = SERVICE_TYPE_BY_NAME_MAPPING.petsitters.serviceJob;
export const DEFAULT_SERVICE_TITLE = SERVICE_TYPE_BY_NAME_MAPPING.petsitters.serviceTitle;

// Sort Filters
export const SORT_FILTER_RECOMMENDED_ID = 'performance';
export const SORT_FILTER_LOCATION_ID = 'location';

export const SORT_FILTER_OPTIONS = [
  {
    label: 'Recommended',
    value: SORT_FILTER_RECOMMENDED_ID,
  },
  {
    label: 'Location',
    value: SORT_FILTER_LOCATION_ID,
  },
];

export const DEFAULT_SORT_FILTER = {
  label: 'Recommended',
  value: SORT_FILTER_RECOMMENDED_ID,
};

// Advanced Filters
export const DEFAULT_PRICE_RANGE = ['0', '300'];

export const SITTERS_HOME_OPTIONS_HOUSE_ID = 'house';
export const SITTERS_HOME_OPTIONS_APARTMENT_ID = 'apartment';
export const SITTERS_HOME_OPTIONS_FENCED_ID = 'fenced';

export const ALLOWED_SITTERS_HOME_OPTIONS = [
  SITTERS_HOME_OPTIONS_APARTMENT_ID,
  SITTERS_HOME_OPTIONS_FENCED_ID,
  SITTERS_HOME_OPTIONS_HOUSE_ID,
];

export const SITTERS_HOME_OPTIONS = [
  {
    label: 'House',
    value: SITTERS_HOME_OPTIONS_HOUSE_ID,
  },
  {
    label: 'Apartment',
    value: SITTERS_HOME_OPTIONS_APARTMENT_ID,
  },
  {
    label: 'Fenced backyard',
    value: SITTERS_HOME_OPTIONS_FENCED_ID,
  },
];

export const PETS_KIDS_AT_HOME_OPTIONS_NO_DOG_ID = 'no_dog';
export const PETS_KIDS_AT_HOME_OPTIONS_NO_CAT_ID = 'no_cat';
export const PETS_KIDS_AT_HOME_OPTIONS_NO_CHILDREN_ID = 'no_children';

export const ALLOWED_PETS_KIDS_AT_HOME_OPTIONS = [
  PETS_KIDS_AT_HOME_OPTIONS_NO_DOG_ID,
  PETS_KIDS_AT_HOME_OPTIONS_NO_CAT_ID,
  PETS_KIDS_AT_HOME_OPTIONS_NO_CHILDREN_ID,
];

export const PETS_KIDS_AT_HOME_OPTIONS = [
  {
    label: 'Doesn’t own a dog',
    value: PETS_KIDS_AT_HOME_OPTIONS_NO_DOG_ID,
  },
  {
    label: 'Doesn’t own a cat',
    value: PETS_KIDS_AT_HOME_OPTIONS_NO_CAT_ID,
  },
  {
    label: 'Doesn’t have children',
    value: PETS_KIDS_AT_HOME_OPTIONS_NO_CHILDREN_ID,
  },
];

export const ABOUT_THE_SITTER_OPTIONS_POLICY_CHECK_ID = 'police_check';
export const ABOUT_THE_SITTER_OPTIONS_FAST_RESPONSE_ID = 'fast_response';
export const ABOUT_THE_SITTER_OPTIONS_PICK_UP_ID = 'pick_up';

export const ALLOWED_ABOUT_THE_SITTER_OPTIONS = [
  ABOUT_THE_SITTER_OPTIONS_POLICY_CHECK_ID,
  ABOUT_THE_SITTER_OPTIONS_FAST_RESPONSE_ID,
  ABOUT_THE_SITTER_OPTIONS_PICK_UP_ID,
];

export const ABOUT_THE_SITTER_OPTIONS = [
  {
    label: 'Police check',
    value: ABOUT_THE_SITTER_OPTIONS_POLICY_CHECK_ID,
  },
  // TODO (WEB-1096): need to investigate the usage of this filter
  // {
  //   label: 'Fast response',
  //   value: ABOUT_THE_SITTER_OPTIONS_FAST_RESPONSE_ID,
  // },
  {
    label: 'Pick-up and drop-off',
    value: ABOUT_THE_SITTER_OPTIONS_PICK_UP_ID,
  },
];

export const SITTER_REVIEWS_ANY_ID = '0';
export const SITTER_REVIEWS_ONE_ID = '1';
export const SITTER_REVIEWS_TEN_ID = '10';

export const ALLOWED_SITTER_REVIEWS_OPTIONS = [
  SITTER_REVIEWS_ANY_ID,
  SITTER_REVIEWS_ONE_ID,
  SITTER_REVIEWS_TEN_ID,
];

export const SITTER_REVIEWS_OPTIONS = [
  {
    label: 'Any',
    value: SITTER_REVIEWS_ANY_ID,
  },
  {
    label: '1+',
    value: SITTER_REVIEWS_ONE_ID,
  },
  {
    label: '10+',
    value: SITTER_REVIEWS_TEN_ID,
  },
];

// not search related query parameters
// that should be tracked in URL as well
export const SEARCH_POSITION_QUERY_PARAMETER = 'searchPosition';
export const SEARCH_ALGORITHM_QUERY_PARAMETER = 'searchAlgorithm';

export const EXTRA_QUERY_PARAMETERS = [
  SEARCH_POSITION_QUERY_PARAMETER,
  SEARCH_ALGORITHM_QUERY_PARAMETER,
];

export const SERVICE_TYPE_DESCRIPTION_MAPPING: {
  [key: string]: string;
} = {
  petsitters:
    "Book a pet sitter to look after your pet while you're away, with all the creature comforts.",
  petminding:
    "Book a pet minder to provide personalised care for your furry friend while you're away.",
  doggydaycare:
    'Book a doggy day care session and your pet will receive personalised playtime all day long!',
  housevisits:
    'Book a pet sitter to stop by your home a few times a day to feed and play with your pet.',
  dogwalkers:
    'Book a dog walker to give them the exercise and socialisation to keep them healthy and happy.',
  doggroomers:
    'Book a dog groomer near you to keep your pet clean, trimmed and looking pooch perfect!',
  dogtrainers: 'Book a dog trainer to take your wild one from untamed to obedient in no time!',
  'pet-groomers':
    'Book a pet groomer and choose from a simple tidy-up to a full service pampering treatment.',
  'pet-boarding':
    "Book a pet sitter to offer personalised care, fun and comfort for your pet while you're away.",
  'dog-boarding':
    "Book a pet sitter who'll provide personalised care in a home environment like they're used to.",
  'dog-washing':
    'Book a Dog Groomer to take them from muddy and stinky to fresh, clean and smelling great!',
  'dog-sitting':
    "Book a dog sitter to give your pet personalised care (and lots of walkies) while you're away.",
  'dog-minding':
    "Book a pet sitter to provide your dog with tailored care for when you're not there.",
  'cat-boarding':
    "Book a pet sitter who'll provide personalised care in a home environment like they're used to.",
  'cat-sitting':
    "Book a pet sitter to look care for your cat while you're away, with all the creature comforts.",
  'rabbit-boarding':
    "Book a pet minder to provide personalised care for your rabbit while you're away.",
  'rabbit-sitting': "Book a pet sitter to feed, cuddle and care for your rabbit while you're away.",
  'puppy-sitting':
    "Book a pet sitter and give your puppy the care, playtime, and comfort they need while you're away.",
  'bird-sitting':
    "Book a pet sitter who'll provide tailored care for your bird, and regular photo updates for you!",
  'bird-boarding':
    "Book a pet sitter to give your bird the personalised care they deserve while you're away.",
  'puppy-daycare':
    'Book doggy day care for your puppy, giving them the playtime & socialisation they need to flourish.',
  'dog-feeding':
    'Book a pet sitter to stop by your home to feed your dog, top up their water, play and cuddle.',
  'cat-feeding':
    'Book a pet sitter to stop by your home to check on your cat, feed them and change their litter tray. ',
};

export const DEFAULT_SERVICE_TYPE_DESCRIPTION = SERVICE_TYPE_DESCRIPTION_MAPPING.petsitters;

export const FENCED_YARD_BADGE_ID = 2;
export const COVID_VACCINATION_ID = 33;
export const EASTER_UPSELL_BADGE_ID = 15;
export const WINTER_HOLIDAYS_BADGE_ID = 16;
export const CHRISTMAS_UPSELL_BADGE_ID = 19;
export const SPRING_UPSELL_BADGE_ID = 24;
export const POLICE_CHECK_ID = 17;

export const CALENDAR_UPDATE_THRESHOLD = 86400 * 7 * 1000;

export const BADGE_MAP: { [key: number]: BadgeIcons } = {
  17: 'shieldStar',
  2: 'fence',
  33: 'shieldCheck',
  40: 'repeatClient',
  41: 'calendarCheck',
  42: 'repeat',
};

export const defaultProfileImage =
  'https://res.cloudinary.com/madpaws/image/upload/c_fill,w_80/v1/img/no-image.png.jpg';
