import FacebookLogin from '@greatsumini/react-facebook-login';
import { Button } from '@madpaws/design-system';
import getConfig from 'next/config';
import React from 'react';

import type { SuccessResponse } from '@greatsumini/react-facebook-login';
import type { ReactElement } from 'react';

const { publicRuntimeConfig } = getConfig();

type Props = {
  onClickLogin: () => void;
  onLoginSuccess: (token: string) => void;
};

export const FacebookLoginButton = ({ onLoginSuccess, onClickLogin }: Props): ReactElement => {
  const renderButton = ({ onClick }: { onClick?: () => void }): ReactElement => {
    const handleOnClick = (): void => {
      onClick?.();
      onClickLogin();
    };

    return (
      <Button
        iconLeading={
          <img
            alt="google icon"
            src={`${publicRuntimeConfig.staticPath}/icons/sign-in/fbIconCircle.svg`}
          />
        }
        isFullBleed
        label="Continue with Facebook"
        onClick={handleOnClick}
        variant="secondary"
      />
    );
  };

  const handleLoginSuccess = (response: SuccessResponse): void => {
    onLoginSuccess(response.accessToken);
  };

  return (
    <FacebookLogin
      appId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID || ''}
      onFail={(error): void => console.log(error.status)}
      onSuccess={handleLoginSuccess}
      render={renderButton}
    />
  );
};
