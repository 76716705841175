import * as yup from 'yup';

import { trackEvent } from '~/components/analytics/analytics';
import { SIGNED_UP_FE_EVENT_NAME } from '~/components/analytics/constants';

import { SignUpMethod } from './AuthDialog';

import type { LoginResponse } from '~/api/graphql/googleSignIn/typeDefs';

export const emailValidationSchema = yup.object({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Please enter a valid email address'),
});

const getAuthType = (loginMethod?: SignUpMethod): string => {
  switch (loginMethod) {
    case SignUpMethod.GOOGLE:
      return 'Google';
    case SignUpMethod.FACEBOOK:
      return 'Facebook';
    default:
      return '';
  }
};

export const trackSignUpFEEvent = (
  data: LoginResponse,
  promoQueryParam?: string,
  loginMethod?: SignUpMethod
): void => {
  trackEvent(SIGNED_UP_FE_EVENT_NAME, {
    id: data.userId,
    user_channel: 'website',
    email: data.email,
    sms_verification_required: false,
    type: getAuthType(loginMethod),
    promo: promoQueryParam,
  });
};
