import { Dialog } from '@madpaws/design-system';
import React, { useEffect, useState } from 'react';

import { FORM_IDS } from '~/common/constants/app';
import { DOM_CONTAINER_ID } from '~/components/constants';

import { MobileVerificationForm } from './MobileVerificationForm';
import { TermsConfirmationForm } from './TermsConfirmationForm';

import type { GoogleSignUpProps } from '../GoogleOneTapLoginProvider';
import type { ReactElement } from 'react';
import type { LoginResponse } from '~/api/graphql/googleSignIn/typeDefs';

export type Props = {
  data: LoginResponse | null;
  isOpen: boolean;
  isTermsFormSubmitting: boolean;
  onDialogClose: () => void;
  onSubmit: (data: GoogleSignUpProps) => void;
  onSuccessLogin: () => void;
  termsFormError: string | null;
};

enum GoogleAuthFormStep {
  Terms = 'terms',
  Mobile = 'mobile',
}

// Exported for use in input validation
export const GoogleSignUpDialog = ({
  data,
  isOpen,
  isTermsFormSubmitting,
  termsFormError,
  onDialogClose,
  onSuccessLogin,
  onSubmit,
}: Props): ReactElement => {
  const [step, setStep] = useState<GoogleAuthFormStep>(GoogleAuthFormStep.Terms);
  const [isNewUser, setIsNewUser] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      const { isUserPhoneNumberVerified, userNeedsToAcceptTerms } = data;
      // if user needs to accept terms, show TermsConfirmationForm
      // regardless the value of isUserPhoneNumberVerified
      if (userNeedsToAcceptTerms) {
        setStep(GoogleAuthFormStep.Terms);
        setIsNewUser(true);

        return;
      }
      // if user don't need to accept terms and user has unverified phone number
      // show MobileVerificationForm
      if (isUserPhoneNumberVerified === false) {
        setStep(GoogleAuthFormStep.Mobile);
      }
    }
  }, [data]);

  const onCancel = (): void => {
    onDialogClose();
  };

  return (
    <Dialog
      domContainerId={DOM_CONTAINER_ID}
      formId={FORM_IDS.SOCIAL_SIGN_UP_FORM}
      heading="Welcome to Mad Paws"
      isOpen={isOpen}
      onRequestClose={onDialogClose}
    >
      {step === GoogleAuthFormStep.Terms && (
        <TermsConfirmationForm
          data={data}
          formError={termsFormError}
          isSubmitting={isTermsFormSubmitting}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      )}
      {step === GoogleAuthFormStep.Mobile && (
        <MobileVerificationForm data={data} isNewUser={isNewUser} onSuccessLogin={onSuccessLogin} />
      )}
    </Dialog>
  );
};
