import { ButtonLink, Dialog, Heading } from '@madpaws/design-system';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import { FORM_IDS } from '~/common/constants/app';
import {
  ACCESS_TOKEN_COOKIE_NAME,
  MD_AUTH_LOCAL_STORAGE_KEY,
  SIGN_IN_ROUTE,
} from '~/common/constants/auth';
import { GoogleSignUpDialog as SocialSignUpDialog } from '~/components/GoogleOAuthProvider/SignUpDialog/GoogleSignUpDialog';
import {
  FACEBOOK_LOGIN_GRANT_TYPE,
  GOOGLE_LOGIN_GRANT_TYPE,
} from '~/components/GoogleOAuthProvider/constants';
import { useUserLoggedInContext } from '~/components/UserAuthProvider/UserAuthProvider';
import { trackEvent } from '~/components/analytics/analytics';
import { USER_CONTINUE_LOGIN_CLICK, USER_LOG_IN_COMPLETED } from '~/components/analytics/constants';
import { DOM_CONTAINER_ID } from '~/components/constants';
import { AuthApi } from '~/services/auth/authApi';
import { RestApi } from '~/services/restApi';
import { removeLocalStorage, setLocalStorage } from '~/utils/localStorage';
import { sentryException } from '~/utils/sentryHelpers';

import styles from './AuthDialog.module.css';
import { EmailSignUpForm } from './EmailSignUpForm';
import { FacebookLoginButton } from './FacebookLoginButton';
import { GoogleLoginButton } from './GoogleLoginButton';
import { trackSignUpFEEvent } from './utils';
import { getSocialLoginArgs } from '../helpers/getSocialLoginArgs';

import type { ReactElement } from 'react';
import type { LoginResponse } from '~/api/graphql/googleSignIn/typeDefs';
import type { SocialLoginArgs } from '~/services/auth/types';

export type Props = {
  handleDialogClose: () => void;
  isOpen: boolean;
  onLoginSuccessRedirectUrl?: string;
  promoQueryParam?: string;
  title: string;
};

export type SocialSignUpProps = {
  firstName?: string;
  lastName?: string;
  newUserAcceptedTerms?: boolean;
  receiveSpecialOffers?: boolean;
  socialToken: string;
};

export enum SignUpMethod {
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
}

export const AuthDialog = ({
  isOpen,
  handleDialogClose,
  title,
  onLoginSuccessRedirectUrl,
  promoQueryParam,
}: Props): ReactElement => {
  const { setIsUserLoggedIn } = useUserLoggedInContext();

  const router = useRouter();

  const [isSignUpDialogVisible, setIsSignUpDialogVisible] = useState<boolean>(false);
  const [loginResponse, setLoginResponse] = useState<LoginResponse | null>(null);
  const [termsFormError, setTermsFormError] = useState<string | null>(null);
  const [isTermsFormSubmitting, setIsTermsFormSubmitting] = useState<boolean>(false);

  const loginMethodRef = React.useRef<SignUpMethod | null>(null);

  const postLoginAction = (): void => {
    setIsSignUpDialogVisible(false);
    setIsUserLoggedIn(true);

    if (onLoginSuccessRedirectUrl) {
      setTimeout(() => {
        router.push(onLoginSuccessRedirectUrl);
      }, 500);
    }
  };

  const onSocialSignInCompleted = (auth: LoginResponse): void => {
    const { userNeedsToAcceptTerms, isUserPhoneNumberVerified } = auth;

    // show the social sign up dialog if user needs to accept TnCs
    if (userNeedsToAcceptTerms) {
      setTermsFormError(null);
      setIsSignUpDialogVisible(true);
      console.log('auth', auth);
      setLoginResponse(auth);

      return;
    }

    // or show the social sign up dialog if user has unverified phone number
    if (isUserPhoneNumberVerified === false) {
      setIsSignUpDialogVisible(true);
      setLoginResponse(auth);
      Cookies.set(ACCESS_TOKEN_COOKIE_NAME, JSON.stringify({ tokens: auth }) ?? '');
      setLocalStorage(MD_AUTH_LOCAL_STORAGE_KEY, { ...auth });
      RestApi.setAuthorizationHeader({
        authorization: `Bearer ${auth.access_token}`,
      });

      return;
    }

    // otherwise complete the login and no need to show the dialog
    Cookies.set(ACCESS_TOKEN_COOKIE_NAME, JSON.stringify({ tokens: auth }) ?? '');
    setLocalStorage(MD_AUTH_LOCAL_STORAGE_KEY, { ...auth });
    RestApi.setAuthorizationHeader({
      authorization: `Bearer ${auth.access_token}`,
    });
    setIsTermsFormSubmitting(false);
    setTermsFormError(null);

    postLoginAction();

    trackEvent(USER_LOG_IN_COMPLETED, {
      grant_type:
        loginMethodRef.current === SignUpMethod.GOOGLE
          ? GOOGLE_LOGIN_GRANT_TYPE
          : FACEBOOK_LOGIN_GRANT_TYPE,
      email: auth.email,
    });
  };

  const sendSentryException = (error: unknown, socialLoginArgs: SocialLoginArgs): void => {
    sentryException(
      error,
      `signInWithSocial API failed, socialLoginArgs: ${JSON.stringify(socialLoginArgs, null, 2)}`
    );
  };

  const submitSocialSignIn = async (
    socialToken: string,
    signUpProps?: SocialSignUpProps,
    onSuccess?: (data: LoginResponse, promoQueryParam?: string, loginMethod?: SignUpMethod) => void
  ): Promise<void> => {
    const {
      route: routerRoute,
      query: { refSource, referralCode },
    } = router;

    const extraArgs = {
      one_tap: routerRoute,
      refSource: (refSource as string) ?? null,
      referralCode: (referralCode as string) ?? null,
    };

    handleDialogClose();

    const socialLoginArgs: SocialLoginArgs = (await getSocialLoginArgs(
      socialToken,
      loginMethodRef.current,
      signUpProps,
      extraArgs
    )) as SocialLoginArgs;

    try {
      const res = await AuthApi.loginWithSocials(
        socialLoginArgs,
        loginMethodRef.current as SignUpMethod
      );
      onSocialSignInCompleted(res as LoginResponse);
      onSuccess?.(res as LoginResponse, promoQueryParam, loginMethodRef.current as SignUpMethod);
    } catch (error) {
      sendSentryException(error, socialLoginArgs);
    }
  };

  const onSocialSignUpDialogClose = (): void => {
    removeLocalStorage(MD_AUTH_LOCAL_STORAGE_KEY);
    Cookies.remove(ACCESS_TOKEN_COOKIE_NAME);
    setIsUserLoggedIn(false);
    setIsSignUpDialogVisible(false);
    setTermsFormError(null);
  };

  const onSocialSignUpFormSubmit = (data: SocialSignUpProps): void => {
    setIsTermsFormSubmitting(true);
    setTermsFormError(null);
    submitSocialSignIn(data.socialToken, data, trackSignUpFEEvent);
  };

  const handleLogIn = (): void => {
    router.push(
      `${SIGN_IN_ROUTE}?redirectUrl=${
        onLoginSuccessRedirectUrl ?? encodeURIComponent(router.asPath)
      }&promo=${promoQueryParam}`
    );
  };

  const onClickGoogleLogin = (): void => {
    loginMethodRef.current = SignUpMethod.GOOGLE;
    trackEvent(USER_CONTINUE_LOGIN_CLICK, { grant_type: GOOGLE_LOGIN_GRANT_TYPE });
  };

  const onClickFacebookLogin = (): void => {
    loginMethodRef.current = SignUpMethod.FACEBOOK;
    trackEvent(USER_CONTINUE_LOGIN_CLICK, { grant_type: FACEBOOK_LOGIN_GRANT_TYPE });
  };

  return (
    <>
      <Dialog
        domContainerId={DOM_CONTAINER_ID}
        formId={FORM_IDS.SIGN_UP_FORM}
        heading="Welcome to Mad Paws"
        isOpen={isOpen}
        onRequestClose={handleDialogClose}
      >
        <div className={styles.dialogContainer}>
          <Heading alignment="centerAlign" level={4} size="small2x">
            {title || `Create a free account or log in`}
          </Heading>

          <GoogleLoginButton
            onClickLogin={onClickGoogleLogin}
            onLoginSuccess={submitSocialSignIn}
          />

          <FacebookLoginButton
            onClickLogin={onClickFacebookLogin}
            onLoginSuccess={submitSocialSignIn}
          />

          <div className={styles.divider}>
            <span className={styles.hr} />
            <p>or sign up with email</p>
            <span className={styles.hr} />
          </div>

          <EmailSignUpForm
            onLoginSuccessRedirectUrl={onLoginSuccessRedirectUrl}
            promoQueryParam={promoQueryParam}
          />

          <div className={styles.login}>
            <p>Have a Mad Paws account? </p>

            <ButtonLink onClick={handleLogIn}>Log in</ButtonLink>
          </div>
        </div>
      </Dialog>

      <SocialSignUpDialog
        data={loginResponse}
        isOpen={isSignUpDialogVisible}
        isTermsFormSubmitting={isTermsFormSubmitting}
        onDialogClose={onSocialSignUpDialogClose}
        onSubmit={onSocialSignUpFormSubmit}
        onSuccessLogin={postLoginAction}
        termsFormError={termsFormError}
      />
    </>
  );
};
