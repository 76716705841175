import { useMediumToMediaQuery, useSmallToMediaQuery } from '@madpaws/design-system';
import { useMemo } from 'react';

export const useDeviceType = (): string => {
  const isSmallToViewport = useSmallToMediaQuery();
  const isMediumToViewport = useMediumToMediaQuery();

  const deviceType = useMemo(() => {
    if (isSmallToViewport) {
      return 'mobile_web';
    }
    if (isMediumToViewport) {
      return 'tablet_web';
    }

    return 'desktop_web';
  }, [isMediumToViewport, isSmallToViewport]);

  return deviceType;
};
