import { getAccessToken } from '~/api/graphql/middlewares';
import { ACCESS_TOKEN_COOKIE_NAME } from '~/common/constants/auth';

import type { Request } from 'express';

type RequestHeader = Record<string, string>;

const getAccessTokenFromRequest = (request: Request): string | null => {
  const userTokenCookie = request.cookies[ACCESS_TOKEN_COOKIE_NAME];

  if (!userTokenCookie) {
    return null;
  }

  let tokenData: { access_token: string };

  try {
    tokenData = JSON.parse(decodeURIComponent(userTokenCookie)).tokens;
  } catch {
    return null;
  }

  return tokenData.access_token;
};

export const getAuthorizationHeader = (request: Request): RequestHeader => {
  const accessToken = getAccessTokenFromRequest(request);

  if (!accessToken) {
    return {};
  }

  return {
    authorization: `Bearer ${accessToken}`,
  };
};

export const getAuthorizationHeaderFromCookie = (): RequestHeader => {
  const accessToken = getAccessToken();

  if (!accessToken) {
    return {};
  }

  return {
    authorization: `Bearer ${accessToken}`,
  };
};

export const getJWTData = (token: string): { sub: number } | null => {
  try {
    const [, payload] = token.split('.').slice(0, 2);

    return JSON.parse(atob(payload));
  } catch (e) {
    return null;
  }
};
