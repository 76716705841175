export const SFS_HOME_SEARCH_TEST_EPPO_ID = 'web-2403-sfs-home-search-test';
export const SFS_HOME_SEARCH_TEST_EPPO_ID_VALUES = {
  ON: 'v1',
  OFF: 'off',
};

export const NEW_CUSTOMER_COUPON_EPPO_ID = 'web-2425-new-customer-coupon';
export const NEW_CUSTOMER_COUPON_EPPO_ID_VALUES = {
  ON: 'v1',
  OFF: 'off',
};
