import {
  HOME_PAGE_SLUG,
  HOW_IT_WORKS_PAGE_SLUG,
  SEARCH_RESULTS_PAGE_SLUG,
} from '~/common/constants/pageLayout';

export const WHITE_LIST_OF_ROUTES = [
  HOME_PAGE_SLUG,
  SEARCH_RESULTS_PAGE_SLUG,
  HOW_IT_WORKS_PAGE_SLUG,
];

export const CHECKBOXGROUP_NAME = 'checkboxOptions';
export const CHECKBOX_TERMS_NAME = 'terms';
export const CHECKBOX_SPECIAL_OFFERS_NAME = 'offers';
export const CHECKBOX_OPTIONS = [
  {
    label: 'I’d like to receive tips on pet ownership and exclusive offers from Mad Paws.',
    value: CHECKBOX_SPECIAL_OFFERS_NAME,
  },
  {
    label:
      'I confirm I am 18 years of age or older and agree to the Mad Paws Terms of Service and Privacy Policy.',
    value: CHECKBOX_TERMS_NAME,
  },
];

export const GOOGLE_LOGIN_GRANT_TYPE = 'google_login';
export const FACEBOOK_LOGIN_GRANT_TYPE = 'facebook_login';
